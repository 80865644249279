<template>
  <div class="row">
    <div class="medium-12 columns twelve">
      <div id="mainBody">
        <!-- <form target="msdsList"> -->
        <ValidatedForm :action="formHandlerUrl" method="get" :show-submit-button="false">
          <!-- <input value="*" class="ce" type="hidden" />
          <input value="*" type="hidden" id="kodakCompany" name="kodakCompany" />
          <input value="Kodak" type="hidden" id="compName" name="compName" />
          <input value="4649" type="hidden" id="pq-path" name="pq-path" />
          <input value="US_en" type="hidden" id="pq-locale" name="pq-locale" />
          <input value="E" type="hidden" id="P_LANGU" name="P_LANGU" />
          <input value="8" type="hidden" id="P_SYS" name="P_SYS" />
          <input value="0" type="hidden" id="P_SSN" name="P_SSN" /> -->
          <div class="">
            <FormLabel input-id="C001" :label-text="$t('Report Category')" />
            <ValidatedFormInput
              ref="reportCategoryInput"
              input-id="C001"
              input-name="C001"
              :input-options="reportCategories"
              input-type="select"
              @onValueChange="categoryValueUpdated"
              :title="$t('Choose the report format.')"
            />
          </div>
          <div class="">
            <FormLabel input-id="C004" :label-text="$t('Country/Region - Language: ')" />
            <ValidatedFormInput
              ref="countryRegionInput"
              input-id="C004"
              input-name="C004"
              :input-options="countryRegions"
              input-type="select"
              @onValueChange="countryValueUpdated"
              :title="$t('Choose the Country/Region - Language.')"
            />
          </div>
          <div class="" v-show="['MSDS'].includes(category)">
            <FormLabel input-id="C013" :label-text="$t('Catalog / Reorder Number: ')" />
            <ValidatedFormInput
              ref="catalogValueInput"
              input-id="C013"
              input-name="C013"
              input-type="text"
              :title="$t('Enter the Catalog or Reorder Number')"
            />
          </div>
          <div class="">
            <FormLabel input-id="C014" :label-text="$t('Product Name / Keyword: ')" />
            <ValidatedFormInput
              ref="keywordInput"
              input-id="C014"
              input-name="C014"
              input-type="text"
              :title="$t('Enter the Product Name or Keyword')"
            />
          </div>
          <!-- <input value="Z_WEBEXT" type="hidden" id="C901" name="C901" />
          <input value="27" type="hidden" id="x" name="x" />
          <input value="7" type="hidden" id="y" name="y" /> -->
          <div class="c_buttons c_buttons--center">
            <Button :label-text="$t('Search')" v-on:click.native="updateIframe" />
          </div>
        </ValidatedForm>
      </div>
      <div id="result" v-show="showIframe">
        <iframe :src="iframeSrc" width="100%" ref="msdsList" name="msdsList" id="msdsList"></iframe>
        <!-- https://msds.kodak.com/ehswww/external/result/result_htmllist.jsp?kodakCompany=*&compName=Kodak&pq-path=4649&pq-locale=US_en&P_LANGU=E&P_SYS=8&P_SSN=0&C001=MSDS&C004=US%20%20%20%20%20%20%20%20E&C013=&C014=Methanol&C901=Z_WEBEXT&x=27&y=7 -->
        <!-- https://msds.kodak.com/ehswww/external/result/result_htmllist.jsp?kodakCompany=*&compName=Kodak&pq-path=4649&pq-locale=US_en&P_LANGU=E&P_SYS=8&P_SSN=0&C001=${category}&C004=${country}&C013=${catalog}&C014=${keyword}&C901=Z_WEBEXT&x=27&y=7 -->
      </div>

      <!-- <p>Click on link bellow to change iframe content:</p>
            <a href="http://www.bing.com" target="search_iframe">Bing</a> -
            <a href="http://en.wikipedia.org" target="search_iframe">Wikipedia</a> -
            <a href="http://google.com" target="search_iframe">Google</a> (not allowed in inframe) - 
            <a href="https://msds.kodak.com/ehswww/external/result/result_htmllist.jsp?kodakCompany=*&compName=Kodak&pq-path=4649&pq-locale=US_en&P_LANGU=E&P_SYS=8&P_SSN=0&C001=MSDS&C004=US%20%20%20%20%20%20%20%20E&C013=&C014=Methanol&C901=Z_WEBEXT&x=27&y=7"  target="search_iframe">Methanol</a> -
            <a href="http://eric.is-a-geek.com/" target="search_iframe">Eric's Site</a>

            <iframe src="http://en.wikipedia.org" width="100%" height="100%" name="search_iframe"></iframe> -->
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import { log, error } from 'JS/global.js';
// import { countriesByCurrency, currencies } from '@/settings.js';
// import { reportCategories } from '@/settings.js';
import Button from 'Components/button/Button.vue';
import FormLabel from 'Components/form/FormLabel.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import ValidatedForm from 'Components/form/ValidatedForm.vue';
import ValidatedFormInput from 'Components/form/ValidatedFormInput.vue';

export default {
  name: 'material_data_safety_sheets',
  components: {
    Button,
    FormLabel,
    IconSVG,
    ValidatedForm,
    ValidatedFormInput,
  },
  props: {
    formHandlerUrl: String,
  },
  data() {
    return {
      category: 'MSDS',
      country: 'US        E',
      catalog: '',
      keyword: '',
      showIframe: false,
      // iFrameHeight: 0,
      reportCategories: [
        { label: this.$i18n.t('Product SDS'), value: 'MSDS' },
        { label: this.$i18n.t('Article Information Sheet'), value: 'Z_ART_INFO' },
      ],
      countryRegions: [
        { label: 'Australia - English', value: 'AU        E' },
        { label: 'Austria - German', value: 'DE        D' },
        { label: 'Belgium - Dutch', value: 'BE        N' },
        { label: 'Belgium - French', value: 'BE        F' },
        { label: 'Brazil - Portuguese', value: 'BR        P' },
        { label: 'Bulgaria - Bulgarian', value: 'BG        W' },
        { label: 'Canada - English', value: 'CA        E' },
        { label: 'Canada - French', value: 'CA        F' },
        { label: 'China - Simplified Chinese', value: 'CN        1' },
        { label: 'China - English', value: 'CN        E' },
        { label: 'Croatia - Croatian', value: 'HR        6' },
        { label: 'Czech Republic - Czech', value: 'CZ        C' },
        { label: 'Denmark - Danish', value: 'DK        K' },
        { label: 'Estonia - Estonian', value: 'EE        9' },
        { label: 'Finland - Finnish', value: 'FI        U' },
        { label: 'France - French', value: 'FR        F' },
        { label: 'Germany - German', value: 'DE        D' },
        { label: 'Great Britain - English', value: 'GB        E' },
        { label: 'Greece - Greek', value: 'GR        G' },
        { label: 'Hong Kong - English', value: 'HK        E' },
        { label: 'Hungary - Hungarian', value: 'HU        H' },
        { label: 'Indonesia - Indonesian', value: 'ID        i' },
        { label: 'Italy - Italian', value: 'IT        I' },
        { label: 'Japan - Japanese', value: 'JP        J' },
        { label: 'Korea - Korean', value: 'KR        3' },
        { label: 'Latin America - Spanish', value: 'REG_LA    S' },
        { label: 'Latvia - Latvian', value: 'LV        Y' },
        { label: 'Lithuania - Lithuanian', value: 'LT        X' },
        { label: 'Malaysia - Malaysian', value: 'MY        7' },
        { label: 'Netherlands - Dutch', value: 'NL        N' },
        { label: 'New Zealand - English', value: 'NZ        E' },
        { label: 'Norway - Norwegian', value: 'NO        O' },
        { label: 'Poland - Polish', value: 'PL        L' },
        { label: 'Portugal - Portuguese', value: 'PT        P' },
        { label: 'Romania - Romanian', value: 'RO        4' },
        { label: 'Russia - Russian', value: 'RU        R' },
        { label: 'Slovakia - Slovakian', value: 'SK        ' },
        { label: 'Slovenia - Slovenian', value: 'SI        5' },
        { label: 'Spain - Spanish', value: 'ES        S' },
        { label: 'Sweden - Swedish', value: 'SE        V' },
        { label: 'Switzerland - French', value: 'CH        F' },
        { label: 'Switzerland - German', value: 'CH        D' },
        { label: 'Switzerland - Italian', value: 'CH        I' },
        { label: 'Taiwan - Traditional Chinese', value: 'TW        M' },
        { label: 'Thailand - Thai', value: 'TH        2' },
        { label: 'Turkey - Turkish', value: 'TR        T' },
        { label: 'Ukraine - Ukrainian', value: 'UA        8' },
        { label: 'United States - English', value: 'US        E' },
        { label: 'Vietnam - Vietnamese', value: 'VN        v' },
      ],
      // true: true,
    };
  },
  computed: {
    iframeSrc: function() {
      console.log('Keyword: ' + this.keyword);
      if (this.showIframe) {
        return (
          this.formHandlerUrl +
          `&compName=Kodak&pq-path=4649&pq-locale=US_en&P_LANGU=E&P_SYS=8&P_SSN=0&C001=` +
          this.category +
          `&C004=` +
          this.country +
          `&C013=` +
          this.catalog +
          `&C014=` +
          this.keyword +
          `&C901=Z_WEBEXT&x=27&y=7`
        );
      }
      return '';
    },
  },
  methods: {
    categoryValueUpdated: function(name, value) {
      log('Updating category', name, value);
      this.category = value !== '' ? value : false;
      this.$refs.catalogValueInput.updateValue('');
      this.$refs.keywordInput.updateValue('');
    },
    countryValueUpdated: function(name, value) {
      log('Updating country', name, value);
      this.country = value !== '' ? value : false;
    },
    updateIframe: function(e) {
      e.preventDefault();
      // console.log("Search clicked");
      this.showIframe = false;
      this.category = this.$refs.reportCategoryInput.inputValue;
      this.country = this.$refs.countryRegionInput.inputValue;
      this.catalog = this.$refs.catalogValueInput.inputValue;
      this.keyword = this.$refs.keywordInput.inputValue;
      // console.log("showIframe " + this.showIframe);
      // console.log("Category " + this.category);
      // console.log("Country " + this.country);
      // console.log("Catalog " + this.catalog);
      // console.log("Keyword " + this.keyword);
      // console.log("FormHandlerURL " + this.formHandlerUrl);
      if (this.category == 'MSDS') {
        if (
          (this.catalog != null && this.catalog.trim() != '') ||
          (this.keyword != null && this.keyword.trim() != '')
        ) {
          this.showIframe = true;
          // console.log((this.catalog != null || this.catalog != '') && (this.keyword != null || this.keyword != ''))
        } else {
          alert('You must enter a Catalog / Reorder Number or a Product Name / Keyword or both.');
        }
      }
      if (this.category == 'Z_ART_INFO') {
        if (this.keyword != null && this.keyword.trim() != '') {
          this.showIframe = true;
        } else {
          // console.log("Z_Art_Info without catalog (" + this.catalog + ").");
          alert('You must enter a Product Name / Keyword.');
        }
      }
      // console.log("2 showIframe " + this.showIframe);
    },
    // resizeIframe: function() {
    //   console.log("Resizing iFrame!");
    //   console.log("1. Height " + this.iFrameHeight);
    //   console.log("More Stuff: " + this.$refs['msdsList'].offsetHeight)
    //   console.log("2. Width " + this.$refs['msdsList'].offsetWidth);
    //   console.log("2. Height " + this.$refs['msdsList'].offsetHeight);
    //   console.log("3. Height: " + this.$refs['msdsList'].contentWindow.document.body.scrollHeight);
    //   this.iFrameHeight = this.$refs['msdsList'].contentWindow.document.body.scrollHeight;
    //   this.$refs.msdsList.style.height = this.iFrameHeight + 'px';
    //   console.log("End of resizeIframe");
    // },
  },
  created() {},
  mounted() {
    this.$refs.reportCategoryInput.updateValue('MSDS');
    this.$refs.countryRegionInput.updateValue('US        E');
    this.$refs.catalogValueInput.updateValue('');
    this.$refs.keywordInput.updateValue('');
  },
};
</script>
<style>
#result {
  margin-top: 25px;
}
#msdsList {
  border: 1px solid gray;
  height: 600px;
}
#result .iframe-wrapper {
  border: 1px solid gray;
  height: 600px;
}
#result .iframe-wrapper,
#examples .iframe-wrapper iframe {
  height: 100%;
  width: 100%;
}
</style>
